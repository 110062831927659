@font-face {
  font-family: "Avenir";
  src: url("assets/fonts/AvenirLTStd-Medium.otf") format("opentype");
}

html {
  height: 100%;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  height: 100%;
}

#root {
  height: 100%;
}

.table {
  border-collapse: collapse;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 175px;
  height: 40px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #4a7eff;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
